import React from "react";
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

class QuestionViewer extends React.Component {
    constructor(props) {
        super(props);
        let options = [];
        for(var i=0;i<this.props.question.options.length;i++) {
            options.push({
                value: this.props.question.options[i],
                isChecked: this.props.answer.answer===this.props.question.options [i]?true:false
            })
        }
        this.state = {
            question: this.props.question,
            answer:this.props.answer.answer,
            options: options,
            showModal:false
        }
    }


    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }


    onChangeMultipleChoice = (event, index) => {
        if(this.props.index===0) this.setState({showModal:true})
        const answers = this.state.options;
        answers[index].isChecked = event.target.checked;
        let checkedList = [];

        for(var i=0;i<answers.length;i++) {
            if(answers[i].isChecked) {
                checkedList.push(answers[i].value);
            }
        }
        this.setState({
            answer: checkedList.join(",").trim()
        }, () => {
            this.props.onChangeAnswer(this.props.index, this.state.answer);
        });
    }

    onChangeAnswer = (event) => {
        if(this.props.index===0 && this.state.question.type==="single") this.setState({showModal:true})
        let answer = ""
        if(this.state.question.type === 'text') {
            answer = event.target.value;
        }
        if(this.state.question.type === 'single') {
            answer = event.target.value;
        }

        this.setState({
            answer: answer
        }, () => {
            this.props.onChangeAnswer(this.props.index, this.state.answer);
        });
    }

    render() {
        let optionsRenderer;
        if(this.state.question.type === "text") {
            optionsRenderer = (
                
                <textarea value={this.state.answer}  placeholder={"Enter the answer"} onChange={this.onChangeAnswer} className={"form-control"}/>
            )
        }
        if(this.state.question.type === "multiple") {
            optionsRenderer = (
                <div>
                    {this.state.options.map(({value, isChecked}, index) => {
                        return (
                            <div key={"option-"+index}>
                                <input name={"option-multiple"} onChange={(e) => this.onChangeMultipleChoice(e, index)} value={value} checked={isChecked} type={"checkbox"} /> {value}
                            </div>
                        )
                    })}
                </div>
            )
        }
        if(this.state.question.type === "single") {
            //console.log(this.props.question.options)
           // console.log(this.props.answer)
            optionsRenderer = (
                <form>
                    {this.state.question.options.map((option, index) => {
                    
                        return (
                            <div key={"option-"+index}>
                                <input checked={option===this.props.answer}  onChange={this.onChangeAnswer} name={"option-single"} value={option} type={"radio"} /> {option}
                            </div>
                        )
                    })}
                </form>
            )
        }

        return (
            <div className={"card"} style={{borderWidth:1,borderStyle:"solid",borderColor:this.getRandomColor()}}>
                <div className={"card-body"}>
                    <h5>Question#{this.props.index+1}</h5>
                    <div className={"row form-group"}>
                        <div className={"col-12"}>{this.state.question.name}</div>
                    </div>
                    <div className={"row form-group"}>
                        <div className={"col-12"}>
                            {optionsRenderer}
                        </div>
                    </div>
                </div>


                <Modal show={this.state.showModal} onHide={()=>this.setState({showModal:false})}>
                   
                    <Modal.Body>
                    <h5 style={{fontWeight:200}}>Please shifts to AR app followed by Brand message then back to microsite again</h5>
                    </Modal.Body>
                    <Modal.Footer style={{borderTop:"none"}}>
                        <Button variant="outline-primary" onClick={()=>this.setState({showModal:false})}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default QuestionViewer;