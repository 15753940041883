import React from "react";
import {withRouter} from 'react-router-dom';
import campaignService from "../services";
import QuestionViewer from "./QuestionViewer";
import Notifications from "../../utils/notifications";

import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import OtpInput from 'react-otp-input';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Badge from 'react-bootstrap/Badge'
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import "../styles/OTPViewer.css"

const discalimerText="I am above 18 and agree to provide the information voluntarily which may be used for research purposes."


const cancelReason_1="OTP Not received"
const cancelReason_2="Consumer not willing to continue"



class OTPViewer extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            phone:this.props.phone,
            outletCode:this.props.outletCode,
            outletName:this.props.outletName,
            showOTPModal:false,
            otp:"",
            handlingOTP:false,
            showCancelModal:false,
            cancelReason:"",
            cancelling:false,
            disclaimerCheckbox:false
        }
    }

    
   
    

    validateSchema = () => {
        
        if(!this.state.outletCode) {
            Notifications.error("Outlet Code is required");
            return false;
        }
        if(!this.state.outletName) {
            Notifications.error("Outlet Name is required");
            return false;
        }
       
        return true;
    }

   

    onSendOTP = () => {
        
        if(this.validateSchema()){
            this.setState({handlingOTP:true,otp:""})
            localStorage.setItem('_jti_outletCode',this.state.outletCode)
            localStorage.setItem('_jti_outletName',this.state.outletName)
            const phoneOTP={
                phone_number:this.state.phone
            }
            campaignService.sendOTP(phoneOTP)
            .then(
                (response) => {
                    // console.log(response.data)
                    Notifications.success("OTP Sent");
                    if(response.data.user_exists) {
                        Notifications.alert("This EAS has been contacted before.");
                    }
                    this.setState({showOTPModal:true})
                    this.setState({handlingOTP:false})
                    //this.props.callback(obj);
                    
                },
                (err) => {
                    if(err.response.data.hasOwnProperty("reason")) Notifications.error(err.response.data.reason);
                     else Notifications.error("Something is wrong!");
                    this.setState({handlingOTP:false})
                    //this.setState({showOTPModal:true})
                }
            );
            
            
        }
        
    }

    onVerifyOTP=()=>{
        this.setState({handlingOTP:true})
        const obj={
            phone_number:this.state.phone,
            otp:this.state.otp
        }

        const callBackObj={
            phone:this.state.phone,
            outletCode:this.state.outletCode,
            outletName:this.state.outletName
        }

        campaignService.verifyOTP(obj)
            .then(
                (response) => {
                    //console.log(response.data)
                    Notifications.success("OTP Verified");
                    //this.setState({showOTPModal:true})
                    this.setState({handlingOTP:false})
                    this.props.callback(callBackObj);
                    
                },
                (err) => {
                    Notifications.error("Something is wrong!");
                    this.setState({handlingOTP:false});
                    //this.props.callback(callBackObj);
                }
            );

    }


    onSubmitCancel=()=>{
        this.setState({cancelling:true})
        const obj={
            phone:this.state.phone,
            reason:this.state.cancelReason
        }
        campaignService.cancelActivity(this.props.campaign.id,obj)
            .then(
                (response) => {
                    //console.log(response.data)
                    Notifications.error("Survey Cancelled");
                    //this.setState({showOTPModal:true})
                    this.setState({showCancelModal:false,phone:"",cancelReason:""})
                    this.setState({cancelling:false})
                    
                    
                },
                (err) => {
                    if(err.response.data.hasOwnProperty("reason")) Notifications.error(err.response.data.reason);
                     else Notifications.error("Something is wrong!");
                    this.setState({showCancelModal:false,cancelling:false})
                }
            );

    }

   onChangeDisclaimerAgreement=()=>{
            this.setState(prevState=>({disclaimerCheckbox:!prevState.disclaimerCheckbox}))
   }

   

    onChangeParticipantsPhone = (event) => {
        this.setState({
            phone: event.target.value
        });
    }

    onChangeOutletCode=(event)=>{
        
        this.setState({
            outletCode:event.target.value
        },()=>localStorage.setItem('_jti_outletCode',this.state.outletCode))
    }

    onChangeOutletName=(event)=>{
        this.setState({
            outletName:event.target.value
        },()=>localStorage.setItem('_jti_outletName',this.state.outletName))
    }

    onChangeCancelReason=(event)=>{
        console.log(event.target.value)
        this.setState({
            cancelReason:event.target.value
        })
    }

    handleChangeOTP = otp => this.setState({ otp });



    render() {
        return (
            <div className={"row form-group"}>
                <div className={"col-8 offset-2 form-group"}>
                    <h6 style={{margin:0,fontWeight:200}}>Campaign Name</h6>
                    <h2 style={{marginBottom:"2rem"}}>{this.props.campaign.name}</h2>
                    <h5>Verify Customer's Phone Number</h5>
                </div>
               
                <div className={"col-6 offset-2 form-group"} style={{width:500}}>
                    <InputGroup hasValidation>
                        <InputGroup.Prepend>
                            <InputGroup.Text>+88</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" required  isInvalid={this.state.phone.length===11?false:true} onChange={this.onChangeParticipantsPhone} value={this.state.phone} placeholder={"Participants Phone"}/>
                        { <Form.Control.Feedback type="invalid">
                            Phone Number must be of 11 digits
                        </Form.Control.Feedback>}
                    </InputGroup>
                    
                    <InputGroup hasValidation style={{marginTop:20}}>
                        <InputGroup.Prepend>
                            <InputGroup.Text style={{width:120}}>Outlet Code</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" isInvalid={this.state.outletCode===""?true:false} required onChange={this.onChangeOutletCode} value={this.state.outletCode} placeholder={"Outlet Code"}/>
                        <Form.Control.Feedback type="invalid">
                            Please Enter Outlet Code.
                        </Form.Control.Feedback>
                    </InputGroup>

                    <InputGroup hasValidation style={{marginTop:10}}>
                        <InputGroup.Prepend>
                            <InputGroup.Text style={{width:120}}>Outlet Name</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" required isInvalid={this.state.outletName===""?true:false}  onChange={this.onChangeOutletName} value={this.state.outletName} placeholder={"Outlet Name"}/>
                        <Form.Control.Feedback type="invalid">
                            Please Enter Outlet Name.
                        </Form.Control.Feedback>
                    </InputGroup>
                    
                </div>

               
                <div className={"col-8 offset-2"}>
                    <div style={{marginBottom:20}}>
                        <Badge variant="warning">Disclaimer</Badge>
                        <p style={{fontSize:12,fontWeight:"bold",marginBottom:5}}>{discalimerText}</p>
                        <Form.Check 
                            type={"checkbox"}
                            id={`default-agree`}
                            label={`I agree`}
                            onChange={this.onChangeDisclaimerAgreement}
                            checked={this.state.disclaimerCheckbox}
                        />
                    </div>
                    <button className={"btn btn-primary"} disabled={this.state.phone.length===11 && this.state.disclaimerCheckbox?false:true} onClick={this.onSendOTP}>
                        {this.state.handlingOTP && <Spinner size="sm" animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>}
                        Send OTP
                    </button>
                    <Button variant="danger" className={"btn btn-primary"} style={{marginLeft:7}} disabled={this.state.phone.length===11 && this.state.disclaimerCheckbox?false:true} onClick={()=>{this.setState({showCancelModal:true})}}>
                        Cancel Activity
                    </Button>
                </div>


                {/*OTP Modal */}
                <Modal show={this.state.showOTPModal} onHide={()=>this.setState({showOTPModal:false})}>
                   
                    <Modal.Body style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                        <h3>Enter Verification Code</h3>
                        <OtpInput
                            value={this.state.otp}
                            onChange={this.handleChangeOTP}
                            numInputs={4}
                            separator={<span>-</span>}
                            inputStyle={{width:45,height:45,fontSize:25,margin:10,borderRadius:8}}
                            focusStyle={{outline:"none",border:"2px solid black",borderRadius:8}}
                        />
                        <br/>
                        <CountdownCircleTimer
                            isPlaying={!this.state.handlingOTP}
                            duration={120}
                            size={140}
                            colors={[["#007bff",.8], ["#dc3545"]]}
                            onComplete={() =>this.setState({showOTPModal:false})}
                            strokeWidth={5}
                            strokeLinecap="square"
                    
                            >
                            {renderTime}
                        </CountdownCircleTimer>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.onSendOTP} >
                        Resend
                        </Button>
                        <Button variant="primary" onClick={this.onVerifyOTP}>
                           
                            {this.state.handlingOTP && <Spinner size="sm" animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>}
                            Verify
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*cancel activity Modal */}
                <Modal show={this.state.showCancelModal} onHide={()=>this.setState({showOTPModal:false})}>
                   
                    <Modal.Body style={{display:"flex",flexDirection:"column"}}>
                        <h5>Select Reason to Cancel</h5>
                        <span style={{marginTop:5}}><input onChange={this.onChangeCancelReason} name={"option-single"} value={cancelReason_1} type={"radio"} style={{marginRight:5}} />{cancelReason_1}</span>
                        <span style={{marginTop:5}}><input onChange={this.onChangeCancelReason} name={"option-single"} value={cancelReason_2} type={"radio"} style={{marginRight:5}} />{cancelReason_2}</span>

                    </Modal.Body>
                    <Modal.Footer>
            
                        <Button variant="primary" onClick={this.onSubmitCancel} disabled={this.state.cancelReason===""}>
                            {this.state.cancelling && <Spinner size="sm" animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>}
                            Submit
                            
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}

const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Goodbye !</div>;
    }
  
    return (
      <div className="timer">
        <div className="text" style={{marginBottom:-10}}>Remaining</div>
        <div className="value">{remainingTime}</div>
        <div className="text" style={{marginTop:-10}}>seconds</div>
      </div>
    );
  };

export default withRouter(OTPViewer);