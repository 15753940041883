import React from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {withRouter} from "react-router-dom";
import authenticationService from "../../authentication/services";
import campaignService from "../../campaigns/services/index"
import Profile from "./Profile"
import Spinner from 'react-bootstrap/Spinner'
import Badge from 'react-bootstrap/Badge'

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

class Nav extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            showProfileModal:false,
            surveyDetails:null
        };
    }

    doTokenCheck = () => {
        // console.log(localStorage.getItem("token"));
        var token = localStorage.getItem("token");
        if(token) {
            authenticationService.verifyToken({token: localStorage.getItem("token")})
            .then(
                (response) => {
                    if(this.props.location.pathname ==='/auth/login') {
                        this.props.history.push('/');
                    }
                    this.getUserDetail();
                },
                (err) => {
                    localStorage.clear();
                    this.props.history.push('/auth/login');
                }
            );
        } else {
            this.props.history.push('/auth/login')
            this.setState({
                user: null
            });
        }

        
    }

    getUserDetail = () => {
        authenticationService.getUserDetail()
            .then(
                response => {
                    this.setState({
                        user: response.data
                    });
                },
                err => {

                }
            )
    }

    componentDidMount() {
        //console.log("idiot")
        this.doTokenCheck();
        this.props.history.listen((nextPath, action) => {this.doTokenCheck();});
    }

   

    onClickLogout = (e) => {
        localStorage.clear();
        this.setState({user:null})
        this.props.history.push('/auth/login');
    }

    handleClose=()=>{
        this.setState({showProfileModal:false})
    }

    handleShowSurveyDetails=()=>{
        this.setState({showProfileModal:true})
        
    }

    render() {
        // this.doTokenCheck();
        let username = '';
        var today = new Date();
        var date =today.getDate()+" "+monthNames[today.getMonth()]+" "+today.getFullYear();

        return(
            <div align={"right"}>
                {
                    this.state.user &&
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end"}}>
                        <Button variant="primary"  as="span" onClick={this.handleShowSurveyDetails}><Badge variant="danger">{localStorage.getItem("surveyCount")?localStorage.getItem("surveyCount"):"📄"}</Badge> CS</Button>
                        <Profile me={this.state.user}/>
                            
                        <Button variant="link" onClick={this.onClickLogout} style={{fontFamily:"monospace"}} >Logout</Button>
                        
                            
                    </div>
                }
                <Modal show={this.state.showProfileModal} onHide={this.handleClose} dialogClassName="modal-90w" style={{borderRadius:0}}>
                        <Modal.Header  style={{borderBottom:"none",paddingBottom:0,display:"flex",flexDirection:"column"}}>
                            
                            
                            <h3 style={{fontWeight:400}}> 📄 Survey Progress </h3>
                            
                        </Modal.Header>
                        <Modal.Body>
                       {
                            <div >
                                <h5>{date}</h5>
                                <h5 style={{fontWeight:100}}>Till now you have achieved <strong style={{color:"red"}}>{localStorage.getItem("surveyCount")}</strong> survey</h5>
                            </div>
                           
                       }
                        
                        </Modal.Body>
                        <Modal.Footer style={{borderTop:"none"}}>
                            <Button variant="secondary" onClick={this.handleClose}>
                            Close
                            </Button>
                        </Modal.Footer>
                </Modal>

            </div>

        );
    }
}

export default withRouter(Nav);