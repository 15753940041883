import React from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import SignatureCanvas from 'react-signature-canvas'

export default function SubmissionModal(props) {
    const [show, setShow] = React.useState(true);
    const [drawing,setDrawing]=React.useState(false);
  
    const handleClose = () => {setShow(false);setDrawing(false);props.closingCallback()};
    const handleShow = () => setShow(true);
    const handleCloseWithSuccess=()=>{setShow(false);props.successCallback(drawing)}
  
    return (
      <>
  
        <Modal show={props.show} onHide={handleClose} dialogClassName="modal-90w">
          <Modal.Header closeButton style={{borderBottom:"none",paddingBottom:0}}>
            <Modal.Title style={{fontWeight:400,color:"#007bff"}}>Review for Submission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
          <SubmissionReview reviewInfo={props.submissionReview}/>
          <div style={{background:"#dddddd"}}>
          <h6 style={{marginLeft:5,marginRight:5,paddingBottom:5,paddingTop:5,borderBottom:"1px solid black"}} >Please give your signature here</h6>
          <SignatureCanvas penColor='black'
                           backgroundColor="#dddddd"
                           onEnd={()=>setDrawing(true)}
                           canvasProps={{width: 400, height: 200, className: 'sigCanvas'}} />
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCloseWithSuccess}>
              Submit Survey
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function SubmissionReview({reviewInfo}){
    return(
      <div style={{marginLeft:0}}>
        <p style={{fontFamily:"monospace",margin:0}}>OutletCode : {reviewInfo.outletCode}</p>
        <p style={{fontFamily:"monospace",margin:0}}>OutletName : {reviewInfo.outletName}</p>
        <h6>Name : {reviewInfo.name}</h6>
        <h6>Phone : {reviewInfo.phone}</h6>
        <h6>Age : {reviewInfo.age}</h6>
        <h6>Profession : {reviewInfo.profession}</h6>
        
        {
          reviewInfo.answersList.map((item,index)=>{
            return(
              <div key={index} style={{border:"1px solid #007bff4f",margin:10,paddingLeft:10,borderRadius:10,marginLeft:0}}>
                <p style={{fontFamily:"monospace",color:"grey",margin:0}}>Question#{index+1}</p>
                <h6>{item.question}</h6>
                <p style={{color:item.answer===""?"red":"black",fontSize:14}}>{item.answer===""?"Not Answered":item.answer}</p>
              </div>
            )
          })
        }
      </div>
    )
  }