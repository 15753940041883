import api from "../../utils/network";

const campaignService = {
    getLiveCampaign: function () {
        return api.get('/campaigns/api/v1/live');
    },

    createEntry: function (id, data) {
        return api.post('/campaigns/api/v1/'+id+'/create_entry', data);
    },

    sendOTP:function(data){
        return api.post('otp/api/v1/generate', data);
    },
    verifyOTP:function(data){
        return api.post('otp/api/v1/verify', data);
    },
    getSurveyDetails: function (id) {
        return api.get(`/campaigns/api/v1/${id}/survey_status`);
    },
    sendProfile:function(data){
        return api.post('/core/api/v1/me', data);
    },
    cancelActivity:function(id,data){
        return api.post(`/campaigns/api/v1/${id}/cancel_survey`, data);
    }
}
export default campaignService;