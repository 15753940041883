import React from "react";
import {withRouter} from 'react-router-dom';
import campaignService from "../services";

import Notifications from "../../utils/notifications";

import Carousel from 'react-bootstrap/Carousel'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
const professionOptions=["Service Holder ( চাকুরীজীবী )","Student ( ছাত্র )","Small and Medium Enterprise Businessman ( ক্ষুদ্র ও মাঝারী ব্যাবসায়ী )","Factory Worker ( কারখানা কর্মী )","Shop Assistant ( দোকান সহকারী )","Driver/Helper ( ড্রাইভার/হেল্পার )","Rickshaw/Van Puller ( রিকশাচাওল/ভ্যান চালক )","Mechanic ( মেকানিক )","Garments Factory Worker ( গার্মেন্টস ফ্যাক্টরী ওয়ার্কার )","Farmer ( কৃষক )","Security Guard ( সিকিউরিটি গার্ড )","Unemployed/Dependent ( বেকার )","Others ( অন্যান্য )"]

class CustomerInfoViewer extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            name:this.props.info.name,
            age:this.props.info.age,
            profession:this.props.info.profession
        }
    }


    onChangeAnswer = (index, answer) => {
        let answers = this.state.answersList;
        answers[index] = answer;
        this.setState({
            answersList: [...answers]
        })
    }

   

    validateSchema = () => {
        const answers = this.state.answersList;
        if(!this.state.name) {
            Notifications.error("Name is required");
            return false;
        }
        if(!this.state.age) {
            Notifications.error("Age is required");
            return false;
        }
        if(!this.state.profession) {
            Notifications.error("Profession is required");
            return false;
        }
        return true;
    }

    prepareAnswer = () => {
        const answers = this.state.answersList;
        let response = [];
        for(var i=0;i<answers.length;i++) {
            response.push({
                question: this.state.campaign.schema[i].name,
                answer: answers[i]
            })
        }

        return {
            name: this.state.name,
            phone: this.state.phone,
            campaign: this.state.campaign.id,
            response: response
        }
    }

    entryData = (id, data) => {
        campaignService.createEntry(id, data)
            .then(
                (response) => {
                    // console.log(response.data)
                    Notifications.success("Response recorded successfully");
                    this.setState({
                        name: "",
                        phone: "",
                        campaign: null,
                        answersList: []
                    }, () => {
                        this.getLiveCampaign();
                    });
                },
                (err) => {

                }
            );
    }


    onSubmit = () => {
        const validated = this.validateSchema();
        if(validated) {
            this.props.callback({
                name:this.state.name,
                age:this.state.age,
                profession:this.state.profession
            });
        }
    }

    onChangeParticipantsName = (event) => {
        this.setState({
            name: event.target.value
        });
    }
    onChangeParticipantsAge = (event) => {
        // console.log(event.target.value)
        this.setState({
            age: event.target.value
        });
    }
    onChangeParticipantsProfession = (event) => {
        this.setState({
            profession: event.target.value
        });
    }

    

    render() {
        return (
            <div className={"row form-group"}>
                <div className={"col-8 offset-2 form-group"}>
                    <h6 style={{margin:0,fontWeight:200}}>Campaign Name</h6>
                    <h2 style={{marginBottom:"2rem"}}>{this.props.campaignName}</h2>
                    <h5>Verify Customer's Phone Number</h5>
                </div>

                <InputGroup hasValidation className={"col-6 offset-2 form-group"}>
                        <InputGroup.Prepend >
                            <InputGroup.Text>Name</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={this.onChangeParticipantsName} value={this.state.name} type={"text"} className={"form-control"} placeholder={"Participant's name"}/>
                </InputGroup>
                
                

                <InputGroup hasValidation className={"col-4 offset-2 form-group"}>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Age</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={this.onChangeParticipantsAge}  value={this.state.age} type={"text"} className={"form-control"} placeholder={"Participant's Age"}>
                            <option value=""  disabled style={{fontWeight:300}}>Select Age</option>
                            <option>18-25</option>
                            <option>26-30</option>
                            <option>31-35</option>
                            <option>36-40</option>
                            <option>41-45</option>
                            <option>46-50</option>
                            <option>50+</option>
                        </Form.Control>
                </InputGroup>

                
                <InputGroup hasValidation className={"col-6 offset-2 form-group"}>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Profession</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={this.onChangeParticipantsProfession} value={this.state.profession}   className={"form-control"} placeholder={"Participant's Profession"}>
                            <option value=""  disabled style={{fontWeight:300}}>Select Profession</option>
                            {
                                professionOptions.map(item=><option key={item}>{item}</option>)
                            }
                        </Form.Control>
                </InputGroup>
                
                


                
                <div className={"col-8 offset-2"}>
                    <button className={"btn btn-primary"} onClick={this.onSubmit}>Next</button>
                </div>

            </div>
        )
    }
}

export default withRouter(CustomerInfoViewer);