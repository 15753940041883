import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";
import authenticationService from "../../authentication/services";
import campaignService from "../../campaigns/services/index";
import Spinner from "react-bootstrap/Spinner";
import Notifications from "../../utils/notifications";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      showProfileModal: false,
      region: this.props.me.region,
      area: this.props.me.area,
      territory: this.props.me.territory,
      tabIMEI: this.props.me.tab_imei,
      simNO: this.props.me.sim_no,
      name: this.props.me.name,
      outletCode:
        localStorage.getItem("_jti_outletCode") === null
          ? ""
          : localStorage.getItem("_jti_outletCode"),
      outletName:
        localStorage.getItem("_jti_outletName") === null
          ? ""
          : localStorage.getItem("_jti_outletName"),
    };
  }

  handleClose = () => {
    this.setState({ showProfileModal: false });
  };

  onSubmit = () => {
    this.setState({ submitting: true });
    const obj = {
      region: this.state.region,
      name: this.state.name,
      area: this.state.area,
      territory: this.state.territory,
      tab_imei: this.state.tabIMEI,
      sim_no: this.state.simNO,
      outlet_id: this.state.outletCode,
      outlet_name: this.state.outletName,
    };
    localStorage.setItem("_jti_outletCode", this.state.outletCode);
    localStorage.setItem("_jti_outletName", this.state.outletName);

    campaignService.sendProfile(obj).then(
      (response) => {
        console.log(response.data);
        Notifications.success("Profile updated successfully");
        this.setState({ submitting: false, showProfileModal: false });
      },
      (err) => {
        Notifications.error("Something went wrong");
        this.setState({ submitting: false, showProfileModal: false });
      }
    );
  };

  onChangeRegion = (event) => {
    this.setState({ region: event.target.value });
  };
  onChangeArea = (event) => {
    this.setState({ area: event.target.value });
  };
  onChangeTerritory = (event) => {
    this.setState({ territory: event.target.value });
  };
  onChangeTabIMEI = (event) => {
    this.setState({ tabIMEI: event.target.value });
  };
  onChangeSimNO = (event) => {
    this.setState({ simNO: event.target.value });
  };
  onChangeName = (event) => {
    this.setState({ name: event.target.value });
  };
  onChangeOutletCode = (event) => {
    this.setState({ outletCode: event.target.value });
  };
  onChangeOutletName = (event) => {
    this.setState({ outletName: event.target.value });
  };

  render() {
    return (
      <div>
        <Button
          variant="primary"
          onClick={() => this.setState({ showProfileModal: true })}
          style={{ marginLeft: 5 }}
        >
          👤 {this.props.me.username}
        </Button>

        <Modal
          show={this.state.showProfileModal}
          onHide={this.handleClose}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <h3>Profile 👤</h3>
          </Modal.Header>
          <Modal.Body>
            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Region</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                onChange={this.onChangeRegion}
                value={this.state.region}
                placeholder={"Region"}
              />
            </InputGroup>

            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Area</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                onChange={this.onChangeArea}
                value={this.state.area}
                placeholder={"Area"}
              />
            </InputGroup>

            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Territory</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                onChange={this.onChangeTerritory}
                value={this.state.territory}
                placeholder={"Territory"}
              />
            </InputGroup>

            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Tab IMEI</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                onChange={this.onChangeTabIMEI}
                value={this.state.tabIMEI}
                placeholder={"Tab IMEI"}
              />
            </InputGroup>

            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Sim No</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                onChange={this.onChangeSimNO}
                value={this.state.simNO}
                placeholder={"Sim No"}
              />
            </InputGroup>

            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Name</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                onChange={this.onChangeName}
                value={this.state.name}
                placeholder={"Name"}
              />
            </InputGroup>
            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Campaign Id</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled
                type="text"
                value={localStorage.getItem("campaignID")}
                placeholder={"Name"}
              />
            </InputGroup>
            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Campaign Name</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled
                type="text"
                value={localStorage.getItem("campaignName")}
                placeholder={"Name"}
              />
            </InputGroup>

            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Outlet Code</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled
                type="text"
                onChange={this.onChangeOutletCode}
                value={
                  localStorage.getItem("_jti_outletCode") === null
                    ? ""
                    : localStorage.getItem("_jti_outletCode")
                }
                placeholder={"Outlet Code"}
              />
            </InputGroup>

            <InputGroup hasValidation style={{ padding: 5 }}>
              <InputGroup.Prepend>
                <InputGroup.Text>Outlet Name</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                disabled
                onChange={this.onChangeOutletName}
                value={
                  localStorage.getItem("_jti_outletName") === null
                    ? ""
                    : localStorage.getItem("_jti_outletName")
                }
                placeholder={"outlet Name"}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.onSubmit}>
              {this.state.submitting && (
                <Spinner size="sm" animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
