import React from "react";
import {withRouter} from 'react-router-dom';
import campaignService from "../services";
import QuestionViewer from "./QuestionViewer";
import Notifications from "../../utils/notifications";
import Button from 'react-bootstrap/Button'

import Carousel from 'react-bootstrap/Carousel'

class CampaignQuestionsViewer extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            campaign: this.props.campaign,
            answersList: this.props.answersList,
        }
    }

    

    onChangeAnswer = (index, answer) => {
        let answers = this.state.answersList;
        answers[index] = answer;
        this.setState({
            answersList: [...answers]
        })
        
    }


    validateSchema = () => {
        const answers = this.state.answersList;
        // console.log(answers)
        if(!this.state.name) {
            Notifications.error("Name is required");
            return false;
        }
        if(!this.state.phone) {
            Notifications.error("Phone is required");
            return false;
        }
        for(var i=0;i<answers.length;i++) {
            if(answers[i]="Not Answered") {
                Notifications.error("Question #" + (i+1) + " answer is empty");
                return false;
            }
        }
        return true;
    }

    prepareAnswer = () => {
        const answers = [...this.state.answersList];
        // console.log(answers)
        let response = [];
        for(var i=0;i<answers.length;i++) {
            response.push({
                question: this.state.campaign.schema[i].name,
                answer: answers[i]
            })
        }

        return {
            response: response
        }
    }

    


    onSubmit = () => {
       // const validated = this.validateSchema();
        if(true) {
            const data = this.prepareAnswer();
            //console.log(data);
            this.props.callback(data)
            //this.entryData(this.state.campaign.id, data);
        }
    }

    

    render() {
        if(!this.state.campaign) {
            return (
                <div>
                    <h1>No Live Campaign</h1>
                </div>
            )
        }
        return (
            <div className={"row form-group"}>
                <div className={"col-8 offset-2 form-group"}>
                    <h6 style={{margin:0,fontWeight:200}}>Campaign Name</h6>
                    <h2 style={{marginBottom:"2rem"}}>{this.props.campaignName}</h2>
                    <h5>Verify Customer's Phone Number</h5>
                </div>
                


                <div className={"col-12"}>
                    {this.state.campaign.schema.map((question, index) => {
                        //console.log(this.props.answersList[index])

                        return (
                            <div className={"form-group"} key={"question-holder-"+index}>
                                
                                <div className={"col-8 offset-2"}>
                                
                                    <QuestionViewer
                                        key={"question-viewer-"+index}
                                        question={question}
                                        onChangeAnswer={this.onChangeAnswer}
                                        index={index}
                                        answer={this.state.answersList[index]}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={"col-8 offset-2"}>
                    <button className={"btn btn-primary"} onClick={this.onSubmit}>Next</button>
                </div>

            </div>
        )
    }
}

export default withRouter(CampaignQuestionsViewer);